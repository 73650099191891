import { ThunkAction } from 'redux-thunk';
import { Dispatch, AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { RootState } from '../store';
import { db, storage } from '../../services/firebase';
import { IStudent } from '../../interfaces/IStudent';
import { api } from '../../services/api-functions';
import { asyncForEach } from '../../helpers/async-foreach';
import { deleteUserAccountThunk } from './LoginDucks';

const COLLECTION_USERS = 'users';
const STORAGE_DIRECTORY = 'students';

export const actionType = {
  CREATE_STUDENT_REQUEST: 'CREATE_STUDENT_REQUEST',
  CREATE_STUDENT_SUCCESS: 'CREATE_STUDENT_SUCCESS',
  CREATE_STUDENT_ERROR: 'CREATE_STUDENT_ERROR',
  GET_STUDENT_REQUEST: 'GET_STUDENT_REQUEST',
  GET_STUDENT_SUCCESS: 'GET_STUDENT_SUCCESS',
  GET_STUDENT_ERROR: 'GET_STUDENT_ERROR',
  GET_ALL_STUDENT_REQUEST: 'GET_ALL_STUDENT_REQUEST',
  GET_ALL_STUDENT_SUCCESS: 'GET_ALL_STUDENT_SUCCESS',
  GET_ALL_STUDENT_ERROR: 'GET_ALL_STUDENT_ERROR',
  UPDATE_STUDENT_REQUEST: 'UPDATE_STUDENT_REQUEST',
  UPDATE_STUDENT_SUCCESS: 'UPDATE_STUDENT_SUCCESS',
  UPDATE_STUDENT_ERROR: 'UPDATE_STUDENT_ERROR',
  UPLOAD_STUDENT_BATCH_REQUEST: 'UPLOAD_STUDENT_BATCH_REQUEST',
  UPLOAD_STUDENT_BATCH_SUCCESS: 'UPLOAD_STUDENT_BATCH_SUCCESS',
  UPLOAD_STUDENT_BATCH_ERROR: 'UPLOAD_STUDENT_BATCH_ERROR',
  CHANGE_STATUS_BUTTON_SUCCESS: 'CHANGE_STATUS_BUTTON_SUCCESS',
};

export interface IInitialStateStudent {
  loading: boolean;
  errors: string;
  formData?: IStudent | null;
  students?: IStudent[];
  uploadSuccess?: boolean;
}

const initialState: IInitialStateStudent = {
  loading: false,
  errors: '',
  students: [],
  uploadSuccess: false,
  formData: null,
};

export default function studentReducer(
  state = initialState, action: AnyAction,
): any {
  const { type, payload } = action;

  switch ( type ) {
    case actionType.CREATE_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.CREATE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.CREATE_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.GET_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        formData: payload,
      };
    case actionType.GET_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.GET_ALL_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_ALL_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        students: payload,
      };
    case actionType.GET_ALL_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.UPDATE_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.UPDATE_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.UPLOAD_STUDENT_BATCH_REQUEST:
      return {
        ...state,
        loading: true,
        uploadSuccess: false,
      };
    case actionType.UPLOAD_STUDENT_BATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadSuccess: true,
      };
    case actionType.UPLOAD_STUDENT_BATCH_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.CHANGE_STATUS_BUTTON_SUCCESS:
      return {
        ...state,
        uploadSuccess: payload,
      };
    default:
      return state;
  }
}

export const actions = {
  createStudentRequest: (): AnyAction => (
    { type: actionType.CREATE_STUDENT_REQUEST }),
  createStudentSuccess: (): AnyAction => (
    { type: actionType.CREATE_STUDENT_SUCCESS }),
  createStudentError: ( payload: string ): AnyAction => ({
    type: actionType.CREATE_STUDENT_ERROR,
    payload,
  }),
  getStudentRequest: (): AnyAction => (
    { type: actionType.GET_STUDENT_REQUEST }),
  getStudentSuccess: ( payload?: IStudent ): AnyAction => ({
    type: actionType.GET_STUDENT_SUCCESS,
    payload,
  }),
  getStudentError: ( payload: string ): AnyAction => ({
    type: actionType.GET_STUDENT_ERROR,
    payload,
  }),
  getAllStudentRequest: (): AnyAction => (
    { type: actionType.GET_ALL_STUDENT_ERROR }),
  getAllStudentSuccess: ( payload: IStudent[]): AnyAction => ({
    type: actionType.GET_ALL_STUDENT_SUCCESS,
    payload,
  }),
  getAllStudentError: ( payload: string ): AnyAction => ({
    type: actionType.GET_ALL_STUDENT_ERROR,
    payload,
  }),
  updateStudentRequest: (): AnyAction => (
    { type: actionType.UPDATE_STUDENT_REQUEST }),
  updateStudentSuccess: (): AnyAction => (
    { type: actionType.UPDATE_STUDENT_SUCCESS }),
  updateStudentError: ( payload: string ): AnyAction => ({
    type: actionType.UPDATE_STUDENT_ERROR,
    payload,
  }),
  uploadStudentBatchRequest: (): AnyAction => (
    { type: actionType.UPLOAD_STUDENT_BATCH_REQUEST }),
  uploadStudentBatchSuccess: (): AnyAction => (
    { type: actionType.UPLOAD_STUDENT_BATCH_SUCCESS }),
  uploadStudentBatchError: ( payload: string ): AnyAction => ({
    type: actionType.UPLOAD_STUDENT_BATCH_ERROR,
    payload,
  }),
  changeStatusSuccessButton: ( payload: boolean ): AnyAction => ({
    type: actionType.CHANGE_STATUS_BUTTON_SUCCESS,
    payload,
  }),
};

export const createStudentThunk = ( data: IStudent, file: File ):
ThunkAction<
void, RootState, null, AnyAction
> => async ( dispatch: Dispatch<any> ) => {
  dispatch( actions.createStudentRequest());
  try {
    const result = await api.post(
      '/api/create-user-account', {
        ...data,
      },
    );
    const { id } = result.data;
    const userNew = { ...data, id };
    dispatch( updateStudentThunk( userNew, file ));
    dispatch( actions.createStudentSuccess());
    toast.success( 'Nuevo estudiante agregado.' );
  } catch ( error ) {
    dispatch( actions.createStudentError( 'No se pudo guardar los datos.' ));
  }
};

export const getStudentByIdThunk = ( id: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getStudentRequest());
  try {
    const doc = await db.collection( COLLECTION_USERS ).doc( id ).get();
    if ( doc.exists ) {
      const data = doc.data() as IStudent;
      dispatch( actions.getStudentSuccess( data ));
    } else {
      dispatch( actions.getStudentError( 'El estudiante no existe' ));
    }
  } catch ( error ) {
    dispatch( actions.getStudentError( 'No se pudo obtener los datos.' ));
  }
};

export const getStudentAllThunk = ():
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getAllStudentRequest());
  try {
    db.collection( COLLECTION_USERS )
      .onSnapshot(( querySnapshot ) => {
        const docs: IStudent[] = [];
        if ( !querySnapshot.empty ) {
          querySnapshot.forEach(( doc ) => {
            const student = doc.data() as IStudent;
            student.id = doc.id;
            if ( !student.deleted && student.type === 3 ) {
              docs.push( student );
            }
          });

          dispatch( actions.getAllStudentSuccess( docs ));
        } else {
          dispatch( actions.getAllStudentSuccess( docs ));
          dispatch( actions.getStudentError( 'No existen datos' ));
        }
      });
  } catch ( error ) {
    dispatch( actions.getStudentError( 'No se pudo obtener los datos.' ));
  }
};

export const updateStudentThunk = ( data: IStudent, file?: File ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.updateStudentRequest());
  try {
    let user;
    let url;
    if ( file ) {
      const storageRef = storage.ref();
      const response = await storageRef
        .child( STORAGE_DIRECTORY )
        .child( `${data.id}.jpg` )
        .put( file );
      url = await response.ref.getDownloadURL();
      const { type, ...rest } = data;
      user = rest;
      user.photo = url;
    } else {
      const { photo, type, ...rest } = data;
      user = rest;
    }
    await db.collection( COLLECTION_USERS ).doc( user.id ).update( user );
    dispatch( actions.updateStudentSuccess());
    toast.success( 'Estudiante editado correctamente.' );
  } catch ( error ) {
    dispatch( actions.updateStudentError( 'No se pudo actualizar los datos.' ));
  }
};

export const getStudentDeleteThunk = ( id: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: any ) => {
  dispatch( actions.updateStudentRequest());
  try {
    await db.collection( COLLECTION_USERS )
      .doc( id ).update({ deleted: true });
    dispatch( deleteUserAccountThunk( id ));
    dispatch( actions.updateStudentSuccess());
  } catch ( error ) {
    dispatch(
      actions.updateStudentError( 'No se pudo eliminar al estudiante.' ),
    );
  }
};

export const createStudentBatchThunk = ( data: IStudent[]):
ThunkAction<
void, RootState, null, AnyAction
> => async ( dispatch: Dispatch<any> ) => {
  dispatch( actions.uploadStudentBatchRequest());
  try {
    const result = await api.post(
      '/api/create-users-batch', {
        users: data,
      },
    );
    // eslint-disable-next-line no-console
    console.log( result.data );
    dispatch( actions.uploadStudentBatchSuccess());
  } catch ( error ) {
    dispatch(
      actions.uploadStudentBatchError(
        'No se pudo cargar la lista de estudiantes.',
      ),
    );
  }
};

export const getStudentsBySubjectIdThunk = ( subjectId: string, courseId: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getStudentRequest());
  try {
    const dataRes: any = [];
    const students = await db.collection( COLLECTION_USERS )
      .where( 'courseId', '==', courseId )
      .where( 'type', '==', 3 )
      .get();
    if ( students.empty ) {
      dispatch( actions.getAllStudentSuccess( dataRes ));
      return;
    }
    const docs = students.docs.filter(( doc ) => !doc.data().deleted );
    await asyncForEach( docs, async ( doc: any ) => {
      const item = doc.data();
      const notes:any = [];
      const tasks:any = [];
      const studentTask = await db.collection( 'tasks' )
        .where( 'subjectId', '==', subjectId )
        .get();
      const { docs: docsTask } = studentTask;
      docsTask.forEach(( task ) => (
        tasks.push({ ...task.data(), id: task.id })
      ));
      await asyncForEach( docsTask, async ( docTask: any ) => {
        const allTasks = await db.collection( 'tasks' )
          .doc( docTask.id )
          .collection( 'notes' )
          .get();
        allTasks.forEach(( note ) => (
          notes.push({ ...note.data(), id: note.id })
        ));
      });
      dataRes.push({
        ...item, id: doc.id, notes, tasksTotal: studentTask.size, tasks,
      });
    });
    dispatch( actions.getAllStudentSuccess( dataRes ));
  } catch ( error ) {
    dispatch( actions.getStudentError( 'No se pudo obtener los datos.' ));
  }
};
