import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyD4SJObUCNd8ds_Wnj6Im0ICM7Gk5TcUuA',
  authDomain: 'codex-dios-es-amor.firebaseapp.com',
  projectId: 'codex-dios-es-amor',
  storageBucket: 'codex-dios-es-amor.appspot.com',
  messagingSenderId: '555088790562',
  appId: '1:555088790562:web:9734c3d2ddf31e77cc367b',
  measurementId: 'G-5NYVPJZPJS',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
