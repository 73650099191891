import { ThunkAction } from 'redux-thunk';
import { Dispatch, AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { RootState } from '../store';
import { db } from '../../services/firebase';
import { ISubject } from '../../interfaces/ISubject';
import { asyncForEach } from '../../helpers/async-foreach';
import { actionType as actionTypeBook } from './BookDucks';
import { orderByValue } from '../../helpers/order-array';

const COLLECTION_SUBJECTS = 'subjects';

const COLLECTION_USERS = 'users';

export const actionType = {
  CREATE_SUBJECT_REQUEST: 'CREATE_SUBJECT_REQUEST',
  CREATE_SUBJECT_SUCCESS: 'CREATE_SUBJECT_SUCCESS',
  CREATE_SUBJECT_ERROR: 'CREATE_SUBJECT_ERROR',
  GET_ALL_SUBJECT_REQUEST: 'GET_ALL_SUBJECT_REQUEST',
  GET_ALL_SUBJECT_SUCCESS: 'GET_ALL_SUBJECT_SUCCESS',
  GET_ALL_SUBJECT_ERROR: 'GET_ALL_SUBJECT_ERROR',
  GET_SUBJECT_REQUEST: 'GET_SUBJECT_REQUEST',
  GET_SUBJECT_SUCCESS: 'GET_SUBJECT_SUCCESS',
  GET_SUBJECT_ERROR: 'GET_SUBJECT_ERROR',
  UPDATE_SUBJECT_REQUEST: 'UPDATE_SUBJECT_REQUEST',
  UPDATE_SUBJECT_SUCCESS: 'UPDATE_SUBJECT_SUCCESS',
  UPDATE_SUBJECT_ERROR: 'UPDATE_SUBJECT_ERROR',
  GET_STUDENTS_BY_SUBJECT_SUCCESS: 'GET_STUDENTS_BY_SUBJECT_SUCCESS',
  GET_SUBJECT_BY_TEACHER_SUCCESS: 'GET_SUBJECT_BY_TEACHER_SUCCESS',
};

export interface IInitialStateSubject {
  loading: boolean;
  errors: string;
  formData?: any;
  studentList?: any;
  subjects: ISubject[];
}

const initialState: IInitialStateSubject = {
  loading: false,
  errors: '',
  subjects: [],
  studentList: [],
};

export default function subjectReducer(
  state = initialState, action: AnyAction,
): IInitialStateSubject {
  const { type, payload } = action;

  switch ( type ) {
    case actionType.GET_ALL_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_ALL_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: payload,
      };
    case actionType.GET_ALL_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.CREATE_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.CREATE_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.CREATE_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.UPDATE_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.UPDATE_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionType.UPDATE_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.GET_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        formData: payload,
      };
    case actionType.GET_STUDENTS_BY_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        studentList: payload,
      };
    case actionType.GET_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    case actionType.GET_SUBJECT_BY_TEACHER_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: payload,
      };
    default:
      return state;
  }
}

export const actions = {
  createSubjectRequest: (): AnyAction => (
    { type: actionType.CREATE_SUBJECT_REQUEST }),
  createSubjectSuccess: (): AnyAction => (
    { type: actionType.CREATE_SUBJECT_SUCCESS }),
  createSubjectError: ( payload: string ): AnyAction => ({
    type: actionType.CREATE_SUBJECT_ERROR,
    payload,
  }),
  getAllSubjectRequest: (): AnyAction => (
    { type: actionType.GET_ALL_SUBJECT_REQUEST }),
  getAllSubjectSuccess: ( payload: ISubject[]): AnyAction => ({
    type: actionType.GET_ALL_SUBJECT_SUCCESS,
    payload,
  }),
  getAllSubjectError: ( payload: string ): AnyAction => ({
    type: actionType.GET_ALL_SUBJECT_ERROR,
    payload,
  }),
  updateSubjectRequest: (): AnyAction => (
    { type: actionType.UPDATE_SUBJECT_REQUEST }),
  updateSubjectSuccess: (): AnyAction => (
    { type: actionType.UPDATE_SUBJECT_SUCCESS }),
  updateSubjectError: ( payload: string ): AnyAction => ({
    type: actionType.UPDATE_SUBJECT_ERROR,
    payload,
  }),
  getSubjectRequest: (): AnyAction => (
    { type: actionType.GET_SUBJECT_REQUEST }),
  getSubjectSuccess: ( payload?: any ): AnyAction => ({
    type: actionType.GET_SUBJECT_SUCCESS,
    payload,
  }),
  getStudentBySubjectSuccess: ( payload?: any ): AnyAction => ({
    type: actionType.GET_STUDENTS_BY_SUBJECT_SUCCESS,
    payload,
  }),
  getSubjectError: ( payload: string ): AnyAction => ({
    type: actionType.GET_SUBJECT_ERROR,
    payload,
  }),
  getSubjectsByTeacherIdSuccess: ( payload: ISubject[]): AnyAction => ({
    type: actionType.GET_SUBJECT_BY_TEACHER_SUCCESS,
    payload,
  }),
};

export const getSubjectsAllThunk = ():
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getAllSubjectRequest());
  try {
    db.collection( COLLECTION_SUBJECTS )
      .onSnapshot(( querySnapshot ) => {
        const docs: ISubject[] = [];
        if ( !querySnapshot.empty ) {
          querySnapshot.forEach(( doc ) => {
            const subject = doc.data() as ISubject;
            subject.id = doc.id;
            if ( !subject.deleted ) {
              docs.push( subject );
            }
          });

          dispatch( actions.getAllSubjectSuccess( docs ));
        } else {
          dispatch( actions.getAllSubjectSuccess( docs ));
          dispatch( actions.getAllSubjectError( 'No existen datos' ));
        }
      });
  } catch ( error ) {
    dispatch( actions.getAllSubjectError( 'No se pudo obtener los datos.' ));
  }
};

export const createSubjectThunk = ( data: ISubject ):
ThunkAction<
void, RootState, null, AnyAction
> => async ( dispatch: Dispatch ) => {
  dispatch( actions.createSubjectRequest());
  try {
    await db.collection( COLLECTION_SUBJECTS ).add( data );
    dispatch( actions.createSubjectSuccess());
    toast.success( 'Nueva materia agregada.' );
  } catch ( error ) {
    dispatch( actions.createSubjectError( 'No se pudo guardar los datos.' ));
  }
};

export const getSubjectByIdThunk = ( id: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getSubjectRequest());
  try {
    const doc = await db.collection( COLLECTION_SUBJECTS ).doc( id ).get();
    if ( doc.exists ) {
      const subject = doc.data() as ISubject;
      subject.id = doc.id;
      dispatch( actions.getSubjectSuccess( subject ));
    } else {
      dispatch( actions.getSubjectError( 'La materia no existe' ));
    }
  } catch ( error ) {
    dispatch( actions.getSubjectError( 'No se pudo obtener los datos.' ));
  }
};

export const getStudentBySubjectThunk = ( id: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getSubjectRequest());
  try {
    let dataRes: any = [];
    const doc = await db.collection( COLLECTION_SUBJECTS ).doc( id ).get();
    if ( doc.exists ) {
      const subject = doc.data() as ISubject;
      const students = await db.collection( COLLECTION_USERS )
        .where( 'courseId', '==', subject.courseId )
        .where( 'type', '==', 3 )
        .get();

      students.docs.filter(( student ) => !student.data().deleted ).forEach(( student ) => (
        dataRes.push({ ...student.data(), id: student.id })
      ));
      dataRes = orderByValue( dataRes, 'surname' );
      dispatch(
        actions.getStudentBySubjectSuccess( dataRes ),
      );
    } else {
      dispatch( actions.getSubjectError( 'La materia no existe' ));
    }
  } catch ( error ) {
    dispatch( actions.getSubjectError( 'No se pudo obtener los datos.' ));
  }
};

export const updateSubjectThunk = ( data: ISubject ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.updateSubjectRequest());
  try {
    await db.collection( COLLECTION_SUBJECTS ).doc( data.id ).update( data );
    dispatch( actions.updateSubjectSuccess());
    toast.success( 'Materia editada correctamente.' );
  } catch ( error ) {
    dispatch(
      actions.updateSubjectError( 'No se pudo actualizar la materia.' ),
    );
  }
};

export const updateSubjectBooksThunk = ( id: string, data: string[]):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.updateSubjectRequest());
  try {
    await db.collection( COLLECTION_SUBJECTS )
      .doc( id ).update({ books: data });
    dispatch( actions.updateSubjectSuccess());
    toast.success( 'Libro de materia editado correctamente.' );
  } catch ( error ) {
    dispatch(
      actions.updateSubjectError(
        'No se pudo actualizar  los libros de la materia.',
      ),
    );
  }
};

export const updateSubjectScheduleThunk = ( id: string, data: any[]):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.updateSubjectRequest());
  try {
    await db.collection( COLLECTION_SUBJECTS )
      .doc( id ).update({ schedule: data });
    dispatch( actions.updateSubjectSuccess());
    toast.success( 'Horario de materia editado correctamente.' );
  } catch ( error ) {
    dispatch(
      actions.updateSubjectError(
        'No se pudo actualizar el horario de la materia.',
      ),
    );
  }
};

export const subjectDeleteThunk = ( id: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.updateSubjectRequest());
  try {
    await db.collection( COLLECTION_SUBJECTS )
      .doc( id ).update({ deleted: true });
    dispatch( actions.updateSubjectSuccess());
    toast.success( 'Materia eliminada correctamente.' );
  } catch ( error ) {
    dispatch(
      actions.updateSubjectError( 'No se pudo eliminar la materia.' ),
    );
  }
};

export const getSubjectsByCourseIdThunk = ( courseId: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getSubjectRequest());
  try {
    const dataRes: any = [];
    const books: any = [];
    const subjects = await db.collection( COLLECTION_SUBJECTS )
      .where( 'courseId', '==', courseId )
      .get();
    if ( subjects.empty ) {
      dispatch( actions.getAllSubjectSuccess( dataRes ));
      return;
    }
    const { docs } = subjects;
    await asyncForEach( docs, async ( doc: any ) => {
      const item = doc.data();
      if ( item.books ) {
        await asyncForEach( item.books, async ( book: string ) => {
          // eslint-disable-next-line max-len
          if ( books.filter(( loop: any ) => loop.id === book ).length <= 0 ) {
            const data = await db.collection( 'books' )
              .doc( book )
              .get();
            books.push({ ...data.data(), id: data.id });
          }
        });
      }
      dataRes.push({
        ...item, id: doc.id,
      });
    });
    dispatch({
      type: actionTypeBook.GET_BOOKS_BY_LIST,
      payload: books,
    });
    dispatch( actions.getAllSubjectSuccess( dataRes ));
  } catch ( error ) {
    dispatch( actions.getSubjectError( 'No se pudo obtener los datos.' ));
  }
};

export const getSubjectsByTeacherIdThunk = ( teacherId: string ):
ThunkAction<
void, RootState, null, AnyAction> => async ( dispatch: Dispatch ) => {
  dispatch( actions.getSubjectRequest());
  try {
    const subjects = await db.collection( COLLECTION_SUBJECTS )
      .where( 'teacherId', '==', teacherId )
      .get();

    if ( !subjects.empty ) {
      let data : ISubject[] = [];
      subjects.forEach(( doc ) => {
        const subject = doc.data() as ISubject;
        subject.id = doc.id;
        if ( !subject.deleted ) {
          data.push( subject );
        }
      });
      data = orderByValue( data, 'name' );
      dispatch( actions.getSubjectsByTeacherIdSuccess( data ));
      return;
    }

    dispatch( actions.getSubjectsByTeacherIdSuccess([]));
  } catch ( error ) {
    dispatch( actions.getSubjectError( 'No se pudo obtener los datos.' ));
  }
};
